import React, { useState, useEffect } from "react";
import "animate.css";

const AnimatedSection: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative flex items-center justify-center w-full">
      <img
        src={"/img/screens/qr-mobile.svg"}
        alt="QR Code"
        className={`transform transition-transform min-w-[290px] duration-500 animate__animated max-w-4xl dark:hidden ${
          scrollY > 2300 ? "animate__fadeInLeft" : "animate__fadeOutLeft"
        }`}
      />
      <img
        src={"/img/screens/qr-mobile-dark.svg"}
        alt="QR Code"
        className={`transform transition-transform min-w-[290px] duration-500 animate__animated max-w-4xl hidden dark:flex ${
          scrollY > 2300 ? "animate__fadeInLeft" : "animate__fadeOutLeft"
        }`}
      />
    </div>
  );
};

export default AnimatedSection;
